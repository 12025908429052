@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lato Bold";
  src: url("../src/Assets/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato SemiBold";
  src: url("../src/Assets/Lato-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Medium";
  src: url("../src/Assets/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Regular";
  src: url("../src/Assets/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Light";
  src: url("../src/Assets/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Thin";
  src: url("../src/Assets/Lato-Thin.ttf") format("truetype");
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #effbfc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background: linear-gradient(to right, #58cf51, #2cc2ca);
  transition: 0.4s;
}

input:checked+.slider {
  background-color: transparent;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.list-disc li {
  padding-left: 25px !important;
}

.switch1 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #153B40;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 3px;
  bottom: 3px;
  background: linear-gradient(to right, #58cf51, #2cc2ca);
  transition: 0.4s;
}

input:checked+.slider1 {
  background-color: #153B40;
}

input:focus+.slider1 {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider1:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.slider1.round {
  border-radius: 40px;
}

.slider1.round:before {
  border-radius: 50%;
}

.border123 {
  background: linear-gradient(to right, #58cf51, #2cc2ca);
  border-radius: 11px;
  position: relative;
  z-index: 2;
}

.border123::after {
  content: "";
  width: 98.5%;
  height: 95%;
  border-radius: 11px;
  left: 1px;
  top: 1px;
  background: #093136;
  position: absolute;
  z-index: -1;
}

.gradient-text {
  background: linear-gradient(to right, #2cc2ca, #58CF54);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion-content {
  display: none;
}

.accordion-content.active {
  display: block;
}

.gradient {
  z-index: 1000;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), transparent);
}

html {
  scroll-behavior: smooth;
}

.bg-image {
  background-image: url("../src/Assets/setup_bg.webp");
  background-size: cover;
}

.scrollHide::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1023px) {
  .bg-image {
    background-image: none !important;
    background-size: cover;
  }
}